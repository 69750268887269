<template>
  <div>
    <MyHeader :title="$t('lang.menu_queryreport')" />
    <van-grid :column-num="3" :border="false" icon-size="48px">
      <van-grid-item
        icon="/img/plan.png"
        is-link
        :to="{ name: 'PollingPlan', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolplanrecord')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/query.png"
        is-link
        :to="{ name: 'PollingQuery', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolrecord')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/miss.png"
        is-link
        :to="{ name: 'MissRecordAudit', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolmissrecord')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/even.png"
        is-link
        :to="{ name: 'ItemQuery', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolevent')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/temp.png"
        is-link
        :to="{ name: 'TempPatrolQuery', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_temppatrolquery')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/line.png"
        is-link
        :to="{ name: 'Map', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_patrolmap')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/map2.png"
        is-link
        :to="{ name: 'CurrLocal', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_currlocal')"
      ></van-grid-item>
      <van-grid-item></van-grid-item>
      <van-grid-item></van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import MyHeader from "@/components/Header.vue";
export default {
  components: { MyHeader },
};
</script>
